@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-100.eot');
  src: url('fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-100.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-100.woff') format('woff'),
  url('fonts/MuseoSansCyrl-100.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-100.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-100.svg#MuseoSansCyrl-100') format('svg');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.thin {
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-100Italic.eot');
  src: url('fonts/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-100Italic.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-100Italic.woff') format('woff'),
  url('fonts/MuseoSansCyrl-100Italic.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-100Italic.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-100Italic.svg#MuseoSansCyrl-100Italic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.thin-italic {
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-300.eot');
  src: url('fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-300.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-300.woff') format('woff'),
  url('fonts/MuseoSansCyrl-300.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-300.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.light {
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-500.eot');
  src: url('fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-500.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-500.woff') format('woff'),
  url('fonts/MuseoSansCyrl-500.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-500.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.regular {
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-700.eot');
  src: url('fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-700.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-700.woff') format('woff'),
  url('fonts/MuseoSansCyrl-700.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-700.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.bold {
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-700Italic.eot');
  src: url('fonts/MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-700Italic.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-700Italic.woff') format('woff'),
  url('fonts/MuseoSansCyrl-700Italic.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-700Italic.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-700Italic.svg#MuseoSansCyrl-700Italic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.bold-italic {
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-900.eot');
  src: url('fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-900.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-900.woff') format('woff'),
  url('fonts/MuseoSansCyrl-900.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-900.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.black {
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family:'Museo';
  src: url('fonts/MuseoSansCyrl-900Italic.eot');
  src: url('fonts/MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/MuseoSansCyrl-900Italic.woff2') format('woff2'),
  url('fonts/MuseoSansCyrl-900Italic.woff') format('woff'),
  url('fonts/MuseoSansCyrl-900Italic.ttf') format('truetype'),
  url('fonts/MuseoSansCyrl-900Italic.otf') format('opentype'),
  url('fonts/MuseoSansCyrl-900Italic.svg#MuseoSansCyrl-900Italic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-0525;
}

.black-italic {
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family:'Shadow';
  src: url('fonts/Shadow Regular.eot');
  src: url('fonts/Shadow Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Shadow Regular.woff2') format('woff2'),
  url('fonts/Shadow Regular.woff') format('woff'),
  url('fonts/Shadow Regular.ttf') format('truetype'),
  url('fonts/Shadow Regular.otf') format('opentype'),
  url('fonts/Shadow Regular.svg#Shadow Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0451;
}

.shadow {
  font-family: Shadow, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Shadow';
  src: url('fonts/Shadow Light.eot');
  src: url('fonts/Shadow Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/Shadow Light.woff2') format('woff2'),
  url('fonts/Shadow Light.woff') format('woff'),
  url('fonts/Shadow Light.ttf') format('truetype'),
  url('fonts/Shadow Light.otf') format('opentype'),
  url('fonts/Shadow Light.svg#Shadow Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0451;
}

.shadow-light {
  font-family: Shadow, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
}