html {
  background-color: #f3f3f3;
  min-width: 1300px;
  width: 100%;
}
body {
  margin: 0 auto;
  max-width: 1680px;
  width: 100%;
  min-width: 1300px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);
}

.bgbg {
  background-color: #fff;
}

.mainHeader {
  position: relative;
  z-index: 20;
  height: 260px;
  padding: 20px 0 0 0;
  background: url(../img/headerBg.png) no-repeat center top;
  box-sizing: border-box;

  &_bigger {
    background: url(../img/headerBg2.png) no-repeat center top;
    height: 280px;

    &+.breadcrumbs {
      margin-top: -70px !important;
      margin-bottom: 0;
    }
  }

  &_header3 {
    position: relative;
    z-index: 26;
    background: url(../img/headerBg3.png) no-repeat center top;
    height: 360px;

    &+* {
      position: relative;
      z-index: 27 !important;
      margin-top: -145px !important;
      margin-bottom: 0;

      &+* {
        position: relative;
        z-index: 27;

        &+* {
          position: relative;
          z-index: 25;

          &.slider {
            margin-top: -8px;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    flex: 0 0 16%;
    width: 16%;
    background-image: url(../img/LOGO.png);
    background-size: cover;
  }

  &__left {
    flex: 0 0 48%;
    width: 48%;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    flex: 0 0 31%;
    width: 31%;
  }

  &__rightItem {
    flex: 0 0 42%;
    width: 42%;
  }

  &+* {
    position: relative;
    z-index: 10;
    margin-top: -65px;
  }

  &+.breadcrumbs {
    z-index: 25;
    margin-top: -33px;
  }

  .menu1 li:nth-child(3n-1), .menu2 li:nth-child(3n-1) {
    position: relative;
    left: 25px;
  }
}

.menu1 {
  @extend .ul-reset;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  &_footer {
    display: block;
    margin-bottom: 0;

    li {
      margin-bottom: 15px !important;
      width: 100% !important;

      &:last-child {
        margin-bottom: 0;
      }
      
      a {
        color: #fff;
      }

      &:before {
        background-color: #fff;
      }
    }
  }

  li {
    position: relative;
    flex: 0 0 30%;
    width: 30%;
    margin-bottom: 7px;
    padding-left: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 4px;
      height: 1px;
      background-color: $colorText2;
    }
  }

  &__link {
    @extend .link;
    color: $colorText2;
  }
}

.menu2 {
  @extend .ul-reset;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_footer {
    display: block;

    li {
      width: 100% !important;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        border-color: #fff;
        color: #fff;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  li {
    position: relative;
    flex: 0 0 33%;
    width: 33%;
    margin-bottom: 15px;
  }

  &__link {
    @extend .link;
    border-bottom: 1px dashed lighten($colorText2, 20%);
    @extend .black;
    color: $colorText2;
    text-transform: uppercase;
  }
}

.menu3 {
  @extend .ul-reset;
  display: flex;
  justify-content: center;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-left: 237px;
  }

  &_footer {
    display: block;
    margin-top: 0;

    &:before {
      display: none !important;
    }

    li {
      display: block;
      margin: 5px 0;
      border: none !important;
      padding: 0 0 0 10px;
      background-color: transparent;
      justify-content: flex-start !important;
      
      &:hover {
        a {
          background-color: transparent !important;
        }
      }

      a {
        border: none !important;
        border-bottom: 1px solid !important;
        @extend .link;
        border-radius: 0 !important;
        padding: 0 !important;
        background-color: transparent;
        color: #fff;

        &:hover {
          border-color: transparent !important;
        }
      }

      &:before {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: auto;
        left: 0;
        margin: auto;
        width: 6px;
        height: 1px;
        background-color: #fff;
      }
    }
  }

  &:before {
    content: '';
    display: block;
    border-radius: 100px 0 0 100px;
    flex: 0 0 55px;
    width: 55px;
    padding: 20px 0;
    background: $colorGrey url(../img/menu3.png) no-repeat 20px center;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1px;
      height: 13px;
      background-color: $colorText;
    }

    &:last-child {
      a {
        border-radius: 0 100px 100px 0;
        border-right: 1px solid $colorGrey;
        padding-right: 20px;
      }

      &:before {
        display: none;
      }
    }


    &:hover, &.active {
      a {
        border-color: $colorRedLight;
        background-color: $colorRedLight;

        span {
          border-color: transparent;
          color: #fff;
        }
      }

      &:before {
        opacity: 0;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $colorGrey;
    border-bottom: 1px solid $colorGrey;
    padding: 12px 12px;
    background-color: transparent;
    text-decoration: none;

    span {
      border-bottom: 1px solid;
      color: $colorText;
      @extend .transition;
    }
  }

  &__akcia {
    border-radius: 100px;
    flex: 0 0 165px;
    padding: 14px 0;
    background-color: #f54b65;
    @extend .black;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    @extend .transition;
    
    &:hover {
      background-color: darken(#f54b65, 15%);
    }
  }
}

.phone {
  display: block;
  padding-left: 40px;
  background: url(../img/phoneIcon.png) no-repeat 4px 2px;
  line-height: 20px;
  text-decoration: none;
  color: $colorText2;

  &_contact {
    @extend .black;
    @extend .not-link;
    font-size: 20px;
    line-height: 1.3;
  }

  &_footer {
    padding-left: 50px;
    background: url(../img/phoneIconWhite.png) no-repeat 5px 2px;
    color: #fff;
  }

  span {
    display: block;
    @extend .black;
    font-size: 16px;
  }
}

.address {
  display: block;
  @extend .link-span;
  padding-left: 35px;
  background: url(../img/addressIcon.png) no-repeat left center;
  line-height: 20px;
  text-decoration: none;
  color: $colorText2;

  &_footer {
    padding-left: 50px;
    background: url(../img/addressIconWhite.png) no-repeat 4px center;
    color: #fff;
  }

  span {
    margin-right: 20px;
  }
}

.time {
  display: block;
  padding-left: 40px;
  background: url(../img/clockIcon.png) no-repeat left center;
  line-height: 19px;

  &_footer {
    padding-left: 50px;
    background-image: url(../img/clockIconWhite.png);
    color: #fff;

    span {
      color: #fff !important;
    }
  }

  span {
    display: block;
    color: $colorRed;
  }
}

.slider {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    height: 80px;
    background: url(../img/splash.png) center right;
  }

  &_additional {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 20;
      width: 100%;
      height: 80px;
      background-image: url(../img/splash.png);
      transform: rotate(180deg);
    }

    .work__face {
      &:nth-child(n+4) {
        display: none;
      }
    }

    .owl-prev {
      display: none;
    }

    .owl-next {
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 1;
    }

    .work__image {
      display: block;

      &.withoutReview {
        img {
          max-height: 300px;
        }
      }

      img {
        max-height: 214px;
      }
    }
    
    .work__showReview {
      position: static;
      display: inline-block;
    }

    .slider__itemRightInnerLeft {
      flex: 0 0 250px;
      width: 250px;
    }
  }

  &_topLine {
    .slider__item {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 6px;
        background: url(../img/sliderTopLine.png) no-repeat center center;
      }
    }

    .slider__itemRight {
      border-color: #979797;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    max-width: 1680px;
    width: 100%;
    min-width: 1300px;
    height: 480px;

    .button2 {
      padding-top: 12px;
      padding-bottom: 10px;
    }

    .button1 {
      padding-top: 15px;
      padding-bottom: 13px;
    }
  }

  &__itemLeft {
    flex: 0 0 50%;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__itemLeftInner {
    display: inline-block;
    vertical-align: top;
    padding-top: 95px;
    width: 640px;
    text-align: center;
  }

  &__itemRight {
    border-left: 3px solid #000;
    flex: 0 0 50%;
    width: 50%;
    height: 100%;
    padding: 75px 0 0 70px;
    background-repeat: no-repeat;
    background-position: center center;
    box-sizing: border-box;
  }

  &__itemRightInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 570px;

    .work__faces {
      padding: 0;
    }
  }

  &__itemRightInnerLeft {
    flex: 0 0 210px;
    width: 210px;

    p {
      margin: 12px 0;
      color: #fff;
    }
    
    ul {
      padding: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: 10px;
        color: #fff;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 4px;
          height: 1px;
          background-color: #fff;
        }
      }
    }
  }

  &__itemRightInnerRight {
    position: relative;
    border-left: 3px solid #fff;
    flex: 0 0 245px;
    width: 245px;
    padding-left: 40px;

    img {
      position: absolute;
      right: 16px;
      top: 75px;
    }
  }

  &__itemHeader {
    margin-bottom: 5px;
    width: 420px;
    @extend .shadow;
    font-size: 34px;
    color: #fff;
    line-height: 1.2;
  }

  &__itemText {
    margin: 5px 0 20px;
    width: 360px;
    color: #fff;
    line-height: 1.4;
  }

  &__itemBigText {
    margin: 15px 0;
    width: 430px;
    @extend .shadow;
    font-size: 16px;
    color: #fff;
    line-height: 1.4;
  }

  &__itemFeatures {
    margin: 20px 0 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__itemFeaturesItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
    height: 32px;
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 16px;
    color: #fff;
  }

  .owl-controls {
    @extend .container;
    z-index: 55;
    text-align: center;
    font-size: 0;
  }

  &__header {
    margin-bottom: 10px;
    @extend .shadow;
    font-size: 36px;
  }

  &__text {
    width: 450px;
    line-height: 1.3;
  }
}

.owl-pagination {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: -32px auto 0;
  border-radius: 100px;
  padding: 3px 2px;
  font-size: 0;
  text-align: center;
  overflow: hidden;

  .owl-page {
    display: inline-block;
    width: 21px;
    height: 19px;
    background-color: #000;
    @extend .transition;

    &:first-child {
      border-radius: 100px 0 0 100px;
      padding-left: 1px;
    }

    &:last-child {
      border-radius: 0 100px 100px 0;
      padding-right: 1px;
    }

    &:before {
      content: '';
      display: inline-block;
      margin: 3px 0 0 0;
      border-radius: 100%;
      width: 13px;
      height: 13px;
      background-color: #fff;
    }

    &.active, &:hover {
      &:before {
        background-color: #b6b6b6;
      }
    }
  }
}

.owl-prev, .owl-next {
  position: absolute;
  top: -265px;
  box-shadow: -1px 1px 3px 0 grey;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  background-color: #fff;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    width: 0;
    height: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.owl-prev {
  left: 0;

  &:before {
    left: 17px;
    border-right: 7px solid #000;
  }
}

.owl-next {
  right: 0;

  &:before {
    right: 17px;
    border-left: 7px solid #000;
  }
}

.categories {
  margin-top: 25px;

  &__inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 35px;

  }

  &__item {
    margin-bottom: 55px;
    flex: 0 0 357px;
    width: 357px;

    &_inner {
      margin-bottom: 35px;

      .categories__image:before {
        display: none;
      }

      .categories__itemInfo {
        position: relative;
        z-index: 2;
        margin-top: -28px;
        padding-top: 25px;
        padding-bottom: 10px;
        background: url(../img/ctg.png) no-repeat center top;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 2px;
          background: url(../img/ctgLine.png) no-repeat center center;
        }

        .categories__header {
          margin-bottom: 0;

          span {
            font-size: 15px;
          }
        }
      }
    }
  }

  &__image {
    display: block;
    position: relative;
    width: 357px;
    height: 201px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 15;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 55px;
      background-image: url(../img/splash2.png);
    }
  }

  &__littleImage {
    position: absolute;
    z-index: 15;
    top: 10px;
    right: 10px;
    padding: 4px 4px 12px 4px;
    background-color: #fff;

    img {
      width: 59px;
      height: 47px;
    }
  }

  &__header {
    display: inline-block;
    margin: 15px 0 10px 10px;
    line-height: 1.3;
    text-decoration: none;

    &:hover {
      span {
        border-color: inherit;
      }
    }

    span {
      border-bottom: 1px solid transparent;
      @extend .shadow;
      font-size: 18px;
      color: #000000;
      @extend .transition;
    }
  }

  &__text {
    margin-left: 10px;
    color: $colorText2;
    line-height: 1.4;
  }

  .slider__itemFeaturesItem {
    margin-left: 10px;
    color: $colorText;
  }

  .slider__itemFeatures {
    margin: 10px 0 15px;
  }

  .button1, .button2 {
    margin-left: 10px;
  }

  .button1 {
    padding-top: 14px;
    padding-bottom: 12px;
  }

  .button2 {
    padding-top: 12px;
    padding-bottom: 10px;
  }
}

.works {
  margin: 20px 0;

  &__header {
    @extend .header;
  }

  &__inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    flex: 0 0 310px;
    margin-bottom: 30px;

    &:hover {
      .works__itemInfo {
        background-color: #f3f7f9;
      }

      .works__itemImage:before {
        background-image: url(../img/splashReviewHover.png);
      }
    }
  }

  &__itemImage {
    position: relative;
    display: block;
    width: 310px;
    height: 270px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;

    img {
      position: relative;
      z-index: 10;
      width: 100%;
    }

    span {
      display: block;
      position: absolute;
      z-index: 20;
      top: 8px;
      right: 8px;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      background: #fff url(../img/square.png) no-repeat center center;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 20;
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 55px;
      background: url(../img/splashReview.png) no-repeat center center;
      background-size: 350px auto;
    }
  }

  &__itemInfo {
    padding-bottom: 15px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    width: 50%;
    padding: 1px 8px;
    line-height: 1.3;
    font-size: 13px;
  }

  &__more {
    @extend .headerLine;
    text-align: center;

    span {
      border-bottom: 1px dashed lighten($colorText, 40%);
      padding-bottom: 2px;
      @extend .black;
      font-size: 20px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.loading {
  @extend .work_loading;
}

.work {
  display: none;
  width: 1024px;
  background-color: transparent;
  position: relative;

  &_loading {
    .work__inner {
      opacity: 0;
    }
    .work__showReview {
      opacity: 0;
    }
    .work__bottom {
      opacity: 0;
    }
    .fancybox-close-small {
      opacity: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 6px solid rgba(100, 100, 100, 0.4);
      border-top: 6px solid rgba(255, 255, 255, 0.6);
      border-radius: 100%;
      height: 50px;
      width: 50px;
      -webkit-animation: fancybox-rotate .8s infinite linear;
      animation: fancybox-rotate .8s infinite linear;
      background: transparent;
      z-index: 99999;
    }

    @-webkit-keyframes fancybox-rotate {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
      to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

    @keyframes fancybox-rotate {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
      to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

  }

  &__inner {
    display: flex;
    justify-content: space-between;
    height: 450px;
    opacity: 1;
    @extend .transition;
  }

  &__left {
    position: relative;
    flex: 0 0 749px;
    width: 779px;
    padding: 0 0 80px 0;
    background-size: cover;
    text-align: center;

    &_main {
      flex: 0 0 699px;
      width: 699px;
      padding: 0 50px 80px 0;
    }

    &_interier {
      display: none;
      background-size: cover;
    }

    &_review {
      display: none;

      img {
        height: 450px;
      }
    }
  }

  &__leftInterierSlider {
    width: 749px;
  }

  &__reviewSlider {
    width: 749px;
  }

  &__toInterier {
    @extend .link-span;
    margin-bottom: 40px;
    padding-top: 20px;

    span {
      display: inline-block;
      vertical-align: top;
      border-bottom: 2px dashed;
      @extend .shadow;
      font-size: 16px;
      cursor: pointer;
      line-height: 20px;
    }
  }

  &__image {
    display: inline-block;
    position: relative;
    font-size: 0;

    &_noshadow {
      .work__imageInner {
        img {
          box-shadow: none;
        }
      }
    }

    &.center {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 95%;

      .work__imageInner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    &:hover {
      .work__imageLoop {
        opacity: 1;
      }
    }

    &.border {
      box-shadow: 0 0 5px 1px #292929;
      border: 7px solid #292929;

      .work__imageInner {
        border: 6px solid #f1f1f1;
        box-shadow: 0 0 5px 1px #fff;
      }
    }
  }

  &__imageInner {

    img {
      box-shadow: 2px 2px 18px 0px rgba(50, 50, 50, 0.96);
      max-width: 540px;
      max-height: 320px;
    }
  }

  &__imageLoop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 52px;
    height: 52px;
    background-image: url(../img/loop.png);
    opacity: 0;
    @extend .transition;
  }

  &__showReview {
    position: absolute;
    left: 18%;
    top: 400px;
    width: 352px;
    height: 104px;
    padding: 37px 0 0 0;
    background-image: url(../img/kliaksa.png);
    @extend .shadow;
    font-size: 22px;
    color: #fff;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    opacity: 1;
    @extend .transition;
  }

  &__right {
    position: relative;
    flex: 0 0 275px;
    width: 275px;
    padding: 15px 0 0 0;
    background-image: url(../img/splashVert1.png);
    background-size: 100% 100%;
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -83px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 83px;
      background-image: url(../img/splashVert2.png);
      background-size: 100% 100%;
    }

    p {
      margin: 12px 0;
      line-height: 1.4;
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: 10px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 4px;
          height: 1px;
          background-color: $colorText2;
        }
      }
    }
  }

  &__faces {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 50px 0 0;
  }

  &__face {
    margin-bottom: 10px;
    margin-left: 14px;
    flex: 0 0 55px;
    width: 55px;
    height: 55px;
    padding: 5px 5px 12px 5px;
    background-color: #e7e0d0;
    box-shadow: 0 1px 1px 0 grey;

    &:nth-child(3n-2) {
      margin-left: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__rightBigText {
    @extend .black;
    font-size: 16px;
    color: $colorText2;
    text-transform: uppercase;
  }

  &__bottom {
    display: flex;
    opacity: 1;
    @extend .transition;
  }

  &__bottomLeft {
    flex: 0 0 749px;
    width: 749px;
    padding: 60px 0 0 0;
  }

  &__bottomLeftInner {
    display: none;
    padding: 0 60px 30px;

    hr {
      display: block;
      width: 100%;
      border: none;
      height: 1px;
      background-color: #fff;
      margin: 30px 0 15px;
    }
  }

  &__bottomRight {
    position: relative;
    flex: 0 0 275px;
    width: 275px;
    padding: 30px 0 0 0;

    img {
      position: absolute;
      top: 107px;
      right: 45px;
    }
  }

  &__bottomRightHeader {
    margin-bottom: 5px;
    @extend .shadow;
    font-size: 24px;
    color: #fff;
  }

  &__bottomRightText {
    @extend .light;
    font-size: 16px;
    color: #fff;
    line-height: 1.3;
  }

  &__bottomRightLink {
    @extend .link;
    color: #fff;
  }

  &__reviewWho {
    padding: 0 0 0 50px;
    background: url(../img/mm.png) no-repeat left center;
  }

  &__reviewWhoName {
    @extend .shadow;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
  }

  &__reviewWhoText {
    font-size: 13px;
    color: #fff;
    line-height: 1.2;
  }

  .owl-prev {
    top: 200px;
    left: 10px;
  }

  .owl-next {
    top: 200px;
    right: 80px;
  }

  .owl-pagination {
    z-index: 55;
    margin-top: -60px;
  }

  &__buttonLeft {
    @extend .owl-prev;
    left: -60px !important;
    cursor: pointer;
  }

  &__buttonRight {
    @extend .owl-next;
    right: -60px !important;
    cursor: pointer;
  }

  &__leftInterierSliderItem {
    height: 450px;
    background-size: cover;
  }
}

.requestForm {
  position: relative;
  z-index: 20;
  height: 460px;
  padding-top: 80px;
  background: url(../img/requestBg.png) no-repeat center center;
  box-sizing: border-box;

  &_pink {
    background: url(../img/requestBgPink.png) no-repeat center center;

    [type="submit"] {
      box-shadow: 0 2px 5px 0 $colorPink;
      background-color: $colorPink;

      &:hover {
        background-color: #fff;
      }
    }
  }

  &_purple {
    height: 507px;
    padding-top: 100px;
    background: url(../img/requestBgP.png) no-repeat center center;

    [type="submit"] {
      box-shadow: 0 2px 5px 0 $colorPurple;
      background-color: $colorPurple;

      &:hover {
        background-color: #fff;
      }
    }
  }

  &__inner {
    margin: 0 auto;
    width: 950px;
    text-align: center;
  }

  &__header {
    display: inline-block;
    width: 439px;
    height: 100px;
    padding-top: 43px;
    background-image: url(../img/klWhite.png);
    @extend .shadow;
    font-size: 28px;
    box-sizing: border-box;
  }

  &__header2 {
    @extend .shadow;
    font-size: 28px;
    color: #fff;
  }

  &__text {
    margin: 5px 0 15px;
    @extend .shadow;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
  }

  &__fieldset {
    display: flex;
    justify-content: space-between;
  }

  &__textInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 25px 0 20px;
  }

  &__textInnerLeft {
    flex: 0 0 48%;
    width: 48%;
    font-size: 18px;
    color: #fff;
    line-height: 1.3;
    text-align: right;
  }

  &__textInnerRight {
    position: relative;
    flex: 0 0 43%;
    width: 43%;
    text-align: left;

    a {
      @extend .link;
      @extend .shadow;
      font-size: 24px;
      color: #fff;
      line-height: 1.3;
    }

    &:before {
      content: '';
      position: absolute;
      left: -59px;
      top: -35px;
      width: 22px;
      height: 76px;
      background-image: url(../img/arr.png);
      transform: rotate(-90deg);
    }
  }

  .input {
    margin-bottom: 15px;
  }

  .control-label {
    padding-top: 17px;
    padding-bottom: 14px;
  }

  .loading {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 1px;
    transform: translate(200px, 0);
    width: 56px;
    height: 56px;
  }
}

.content {
  margin-top: 20px;
  margin-bottom: 20px;

  &_small {
    margin-left: auto;
    margin-right: auto;
    width: 950px;
  }

  &_column3 {
    column-count: 3;

    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &_column2 {
    column-count: 2;

    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  p, .p {
    margin: 15px 0;
  }

  hr {
    margin: 20px 0;
    border: none;
    height: 1px;
    background-color: $colorText;
  }

  &_bg {
    padding-top: 120px;
    background: url(../img/contentBg.png) no-repeat center top;
  }

  table {
    margin: 15px auto;
    border-collapse: collapse;
    width: 700px;

    th {
      padding: 20px;
      background-color: $colorBlue;
      @extend .black;
      font-size: 18px;
      color: #fff;
      text-align: left;
      vertical-align: middle;
    }
    
    td {
      padding: 15px 0 20px 15px;
      background-color: #fff;
      font-size: 16px;
    }

    td, th {
      border: 1px solid #b6b6b6;
    }
  }

  .table-small {
    width: 150px;
  }
}

.reviews {

  &+.footer {
    margin-top: 30px;
  }
  
  &_beforeFooter {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -109px;
      z-index: 20;
      width: 100%;
      height: 80px;
      background-image: url(../img/splash.png);
      transform: rotate(180deg);
    }
  }

  &__header {
    @extend .header;
    margin-bottom: 20px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 50px;
    flex: 0 0 560px;
    width: 560px;

    &:hover {
      .work__bottomLeftInner {
        background-color: #f3f7f9;
      }

      .reviews__itemImage {

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__itemImage {
    position: relative;

    span {
      @extend .work__showReview;
      top: auto;
      bottom: -10px;
      z-index: 30;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 20;
      width: 100%;
      height: 80px;
    }

    &:before {
      background-image: url(../img/splashReview.png);
      opacity: 1;
    }

    &:after {
      background-image: url(../img/splashReviewHover.png);
      opacity: 0;
    }
  }

  &__itemSlider {
    width: 560px !important;

    a {
      display: inline-block;
      position: relative;
      width: 560px;
      height: 336px;
      background-position: center center;
      background-size: cover;

      &:after {
        content: '';
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 20;
        width: 52px;
        height: 52px;
        background-image: url(../img/loop2.png);
      }
    }

    img {
      max-width: 100%;
    }
  }

  .work__bottomRightText, .work__reviewWhoName, .work__reviewWhoText {
    color: $colorText;
  }
  
  hr {
    background-color: $colorText;
  }

  .owl-next {
    top: 40%;
    right: -20px;
    opacity: 1;
  }

  .owl-prev {
    display: none;
  }

  &__more {
    @extend .works__more;
    position: relative;
  }
}

.advantages {
  height: 755px;
  padding: 150px 0 0 0;
  background: url(../img/advBg.png) no-repeat center center;
  box-sizing: border-box;

  &_additional {
    margin: 30px 0;
    height: auto;
    padding: 0;
    background: none;

    .advantages__image span {
      box-shadow: none;
      background-color: #e4e4e4;
    }

    .advantages__header, .advantages__text {
      color: $colorText2;
    }
  }

  &_bottom {
    position: relative;
    z-index: 55;
    padding-bottom: 30px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -25px;
      width: 100%;
      height: 80px;
      background: url(../img/splash.png) no-repeat center center;
      transform: rotate(180deg);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    flex: 0 0 450px;
    width: 450px;
  }

  &__image {
    position: relative;
    flex: 0 0 114px;
    width: 114px;
    height: 144px;
    background-image: url(../img/aBg.png);

    div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-position: center 47%;
    }

    span {
      position: absolute;
      left: -30px;
      top: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 38px;
      height: 38px;
      background-color: rgba(228, 228, 228, 0.25);
      @extend .black;
      font-size: 16px;
      color: #fff;
    }
  }

  &__info {
    margin-left: 25px;
  }

  &__header {
    @extend .shadow;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
  }

  &__text {
    @extend .light;
    font-size: 16px;
    color: #fff;
    line-height: 1.3;
  }
}

.catalogCategories {

  &__inner {
    counter-reset: list;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 30px;
    @extend .ul-reset;

    &>li {
      position: relative;
      margin-bottom: 25px;
      flex: 0 0 250px;
      width: 250px;
      padding: 0 0 0 40px;

      &.hide {
        &:before {
          display: none;
        }
      }

      &:before {
        counter-increment: list;
        content: "0"counter(list);
        position: absolute;
        left: 0;
        top: -5px;
        border-bottom: 3px solid $colorBlue;
        @extend .black;
        font-size: 16px;
        color: $colorBlue;
      }

      &:nth-child(n+10) {
        &:before {
          content: counter(list);
        }
      }

      &>a {
        @extend .shadow;
        font-size: 18px;
        color: $colorText;
        text-decoration: none;
      }

      ul {
        margin-top: 5px;

        li {
          position: relative;
          margin-bottom: 8px;
          padding-left: 12px;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 8px;
            height: 1px;
            background-color: $colorText2;
          }
          
          a {
            display: inline-block;
            @extend .link;
            @extend .light;
            font-size: 16px;
            color: $colorText;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.catalog {
  margin: 20px 0;
  z-index: 30 !important;

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 40px;
  }

  &__item {
    margin-bottom: 25px;
    flex: 0 0 225px;
    width: 225px;
  }

  &__itemImage {
    position: relative;
    margin-bottom: 5px;
    width: 225px;
    height: 230px;
    background-position: center center;
    background-size: cover;

    &:hover {
      .catalog__itemGetPrice, .catalog__itemShow, &:after {
        visibility: visible;
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 15;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 55px;
      background-image: url(../img/splash2.png);
    }

    &:after {
      content: '';
      visibility: hidden;
      opacity: 0;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 15;
      margin: auto;
      width: 88%;
      height: 0;
      border-top: 2px dashed #fff;
    }
  }
  
  &__itemShow {
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    @extend .shadow;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    @extend .transition;
  }
  
  &__itemGetPrice {
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 10;
    width: 100%;
    height: 45%;
    background-color: rgba(0, 0, 0, 0.8);
    @extend .shadow;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    @extend .transition;
  }

  &__itemNumber {
    margin-bottom: 10px;
    font-size: 13px;
  }

  &__itemCat {
    @extend .black;
    font-size: 13px;
  }

  &__open {
    margin-bottom: 25px;
    padding: 0 30px;
  }

  &__openInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__openTrigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $colorText;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      .catalog__openTriggerText {
        border-color: transparent;
      }
    }
  }

  &__openTriggerMarker {
    flex: 0 0 44px;
    width: 44px;
    height: 44px;
    background-image: url(../img/menuIcon.png);
  }

  &__openTriggerText {
    display: inline-block;
    margin-left: 10px;
    border-bottom: 2px dashed;
    @extend .black;
    font-size: 16px;
    text-transform: uppercase;
    @extend .transition;
  }

  &__openSearch {
    margin-bottom: 0 !important;
  }

  &__openPicked {

  }

  &__openPickedLeft {
    @extend .light;
    font-size: 16px;
  }

  &__openPickedRight {
    @extend .black;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__filter {
    margin: -20px 0 15px;
    padding: 55px 0 30px;
    background: url(../img/filterBg.png) no-repeat center center;
  }

  &__filterInner {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
  }

  &__filterItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__filterText {
    margin-right: 10px;
    @extend .black;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__filterSlider {
    position: relative;
    flex: 0 0 194px;
    width: 194px;
    height: 5px;
    background-color: rgba(181, 218, 245, 0.4);

    .ui-slider-range {
      position: relative;
      height: 100%;
      background-color: #b5daf5;
    }
    
    .ui-slider-handle {
      position: absolute;
      top: -6px;
      width: 5px;
      height: 17px;
      background-color: $colorBlueLight;
    }

    .ui-slider-handle-text {
      position: absolute;
      bottom: -20px;
      left: -3px;
      font-size: 12px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @extend .ul-reset;

  li {
    margin: 0 8px;

    a {
      display: inline-block;
      border-bottom: 3px solid transparent;
      padding-bottom: 1px;
      @extend .black;
      font-size: 16px;
      color: #000;
      text-decoration: none;
      @extend .transition;
    }

    span {
      position: relative;
      bottom: 2px;
      display: inline-block;
      font-size: 16px;
      color: #000;
    }

    &:hover, &.active {
      a {
        border-color: $colorBlue;
        color: $colorBlue;
      }
    }

    &.prev {
      &>* {
        position: relative;
        display: inline-block;
        box-shadow: -1px 1px 3px 0 grey;
        border: none !important;
        border-radius: 100%;
        width: 44px;
        height: 44px;
        background-color: #ededed;
        color: transparent !important;;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 40%;
          margin: auto;
          border-right: 7px solid #000;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          width: 0;
          height: 0;
        }
      }
    }

    &.next {
      &>* {
        position: relative;
        display: inline-block;
        box-shadow: -1px 1px 3px 0 grey;
        border: none !important;
        border-radius: 100%;
        width: 44px;
        height: 44px;
        background-color: #ededed;
        color: transparent !important;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 40%;
          margin: auto;
          border-left: 7px solid #000;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          width: 0;
          height: 0;
        }
      }
    }
  }
}

.footer {
  position: relative;
  z-index: 15;
  margin-top: -85px;
  padding: 110px 0 15px 0;
  background-color: $colorFooter;

  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  &__item {
    border-left: 1px solid #919191;
    flex: 0 0 210px;
    width: 210px;
    padding: 0 20px 10px 20px;

    &:first-child {
      border-left: none;
    }
  }

  &__logo {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: url(../img/LOGO.png) center 20px no-repeat;
  }

  &__line {
    margin: 10px 0 15px;
    height: 4px;
    background: url(../img/lineFooter.png) no-repeat center center;
  }

  &__bottom {
    @extend .light;
    font-size: 11px;
    color: #a6a6a6;
    text-align: center;
    line-height: 1.2;
  }
  
  .menu1 li:before {
    background-color: #fff;
  }

  .menu3 {
    display: block;
    margin: 0;

    li {
      display: block;
      margin-bottom: 15px;
    }

    &:before {
      display: none;
    }

    .menu3__link {
      display: inline-block;
      @extend .link;
      border: none;
      padding: 0 0 0 10px;
      background-color: transparent;
      color: #fff;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 4px;
        height: 1px;
        background-color: #fff;
      }

      span {
        color: #fff;
      }
    }
  }

  .menu2 {
    display: block;

    li {
      width: 100%;
    }

    .menu2__link {
      @extend .link;
      border-bottom: 1px dashed;
      color: #fff;
    }
  }
}

.quickOrder {
  color: #fff;
  text-align: center;

  &__header {
    margin-bottom: 10px;
    @extend .black;
    font-size: 17px;
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 10px;
    @extend .light;
    font-size: 13px;
    line-height: 1.3;
  }
}

#work {
  margin-top: 80px;
  vertical-align: top;
}

.popup {
  display: none;
  width: 440px;
  background-color: transparent;
  text-align: center;
  
  &__header {
    margin-bottom: 10px;
    height: 100px;
    padding-top: 43px;
    background-image: url(../img/klWhite.png);
    @extend .shadow;
    font-size: 28px;
    color: $colorBlue;
    box-sizing: border-box;
  }

  .input {
    margin-bottom: 15px;
  }
}

.field-photoform-file, .field-proschetform-file, .field-requestform-file, .field-requestform2-file {
  display: block;

  input {
    display: none;
  }

  [for="photoform-file"], [for="proschetform-file"], [for="requestform-file"], [for="requestform2-file"] {
    @extend .button4;
    margin-bottom: 15px;
  }
}

.breadcrumbs {
  margin: 20px 0;

  &__inner {
    @extend .ul-reset;
    padding-left: 60px;
  }

  &__item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;

    &:before {
      content: '/';
      position: absolute;
      left: -13px;
      top: 0;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  a {
    @extend .link;
    color: $colorText;
  }
}

.sendForm {
  [type="submit"]:disabled {
    opacity: 0.5;
  }
}

.search {
  display: block;
  margin-bottom: 40px;
  text-align: center;

  &.module {
    display: flex;
    justify-content: center;
    align-items: center;

    &>* {
      margin: 0 20px;
    }
  }

  .radioWithImage__text {
    @extend .black;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__inner {
    display: inline-block;
    position: relative;
    flex: 0 0 205px;
    width: 205px;

    input {
      width: 100%;
      border-radius: 100px;
      border: 1px solid $colorSearch;
      padding: 8px 50px 8px 16px;
      background-color: #fff;
      font-family: $font-family;
      font-size: 13px;
      box-sizing: border-box;
    }

    button {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      border-left: 2px dashed $colorSearch;
      width: 40px;
      height: 26px;
      background: url(../img/searchSubmit.png) no-repeat 8px 3px;
      cursor: pointer;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $colorText;
      opacity: 0.7;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $colorText;
      opacity: 0.7;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $colorText;
      opacity: 0.7;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $colorText;
      opacity: 0.7;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $colorText;
      opacity: 0.7;
    }
  }
}

.catalogItem {
  margin-bottom: 30px;
  z-index: 27 !important;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    flex: 0 0 40%;
    width: 40%;
    padding: 20px 6% 0 4%;
  }

  &__right {
    flex: 0 0 40%;
    width: 40%;
    padding: 10px 6% 0 4%;
    background-color: #f4f4f4;
  }

  &__image {
    display: block;
    margin: 30px 0 45px;

    img {
      max-width: 100%;
    }
  }

  &__name {
    @extend .shadow;
    font-size: 24px;
    line-height: 1.5;
  }

  &__artikul {
    font-size: 12px;
  }

  &__tagsHeader {
    @extend .shadow;
    font-size: 18px;
    line-height: 1.5;
  }

  &__tagsItems {
    @extend .ul-reset;
    line-height: 1.4;
  }

  &__tagsItem {
    display: inline-block;
    position: relative;
    margin-right: 10px;

    a {
      @extend .link;
      color: $colorRedLight;
    }

    &:before {
      content: '/';
      position: absolute;
      right: -6px;
      top: 0;
      color: $colorRedLight;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
  }

  &__hr {
    display: block;
    margin: 30px 0;
    width: 100%;
    height: 1px;
    background-color: #bcbcbc;
  }

  &__info {
    display: block;
  }

  &__infoItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    height: 38px;
    padding-left: 45px;
    background: url(../img/arraar.png) no-repeat left center;

    a {
      border-bottom: 1px dashed lighten($colorText, 40%);
      font-size: 16px;
      color: $colorText;
      text-decoration: none;
      @extend .transition;

      &:hover {
        border-color: transparent;
      }
    }
  }

  &__infoContent {
    display: none;

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.calc {

  &__item {
    margin: 20px 0;
    border-top: 1px solid #b4b4b4;
    padding: 20px 0 0;

    &:first-child {
      border: none;
      padding: 0;
    }
  }

  &__header {
    margin-bottom: 10px;
    @extend .shadow;
    font-size: 18px;
  }

  &__fieldset {
    vertical-align: middle;
  }

  &__inputWrapper {
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;

    span {

    }
  }

  &__input {
    border-radius: 100px;
    border: 3px solid #bdbdbd;
    width: 75px;
    padding: 10px;
    background-color: #fff;
  }

  .radioWithImage {
    margin-right: 30px;
  }

  &__submit {
    display: inline-block;
    margin-top: 10px;
    width: auto;
    padding: 12px 52px 13px;
    background-color: $colorBlueLight;
    font-size: 18px;
  }

  &__submitHeader {
    font-size: 18px;
  }

  &__days {
    font-family: $font-family;
    @extend .black;
    font-size: 36px;
  }

  .input {
    margin: 5px 0;
    border: 3px solid #afafaf;
    box-shadow: none;
    padding: 15px 0 13px 55px;
  }
}

.catalogOpen {
  display: none;
  background-color: transparent;

  .catalogCategories {
    width: 1280px;

    .catalogCategories__inner > li > a {
      color: #fff;
    }

    .catalogCategories__inner>li ul li {
      
      &:before {
        background-color: #fff;
      }
      
      a {
        color: #fff;
      }
    }
  }

  .fancybox-close-small {
    top: -60px;
    right: 70px;
  }
}

.sort {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    margin-right: 10px;
  }
}

.sort__select {

  &.reverse + .ui-selectmenu-button {
    .ui-selectmenu-icon {
      background: url(../img/sortIconReverse.png) no-repeat 8px 6px;
    }
  }

  &+.ui-selectmenu-button {
    display: inline-block;
    position: relative;
    border-radius: 100px;
    border: 1px solid $colorSearch;
    padding: 9px 0;
    background-color: #fff;
    cursor: pointer;

    .ui-selectmenu-icon {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      border-left: 2px dashed $colorSearch;
      width: 40px;
      height: 26px;
      background: url(../img/sortIcon.png) no-repeat 10px 5px;
      cursor: pointer;
    }

    .ui-selectmenu-text {
      display: block;
      width: 100%;
      padding: 0 50px 0 16px;
      font-family: $font-family;
      font-size: 13px;
      box-sizing: border-box;
    }
  }
}

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 55;
  background-color: #fff;
  display: none;

  ul {
    @extend .ul-reset;
    border: 1px solid $colorGrey;
    padding: 10px 5px 0 5px;

    li {
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
.ui-selectmenu-open {
  display: inline-block;
}

.catalogBagets {

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 40px;
    border: 3px solid transparent;
    flex: 0 0 305px;
    width: 305px;
    cursor: pointer;
    @extend .transition;

    &:hover {
      border-color: $colorBaget;
      
      .catalogBagets__itemInfo {
        &:before {
          opacity: 1;
        }
      }
    }

    &.hide {
      border-color: transparent !important;
    }
  }

  &__itemImage {
    display: block;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__itemInfo {
    position: relative;
    margin-top: -65px;
    padding: 95px 50px 20px 50px;
    background-color: transparent;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url(../img/bagetBg.png) no-repeat center 10px;
      opacity: 0;
      @extend .transition;
    }
  }

  &__itemName {
    margin-bottom: 10px;
    @extend .black;
    font-size: 16px;
  }

  &__itemFeature {
    font-size: 13px;
    line-height: 1.3;
  }

  &__itemPrice {
    @extend .black;
    font-size: 20px;
  }

  &__itemInfoInner {
    position: relative;
  }

  &__itemButton {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    border-radius: 100px;
    border: 2px solid #b2b2b2;
    padding: 13px 30px 10px;
    background-color: transparent;
    @extend .black;
    color: #b2b2b2;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    @extend .transition;
    
    &:hover {
      border-color: $colorBlue;
      background-color: $colorBlue;
      color: #fff;
    }
  }
}

.bagetPopup {
  display: none;
  background-color: transparent;

  .header {
    width: $site-width;
    background: url(../img/bagetPopupHeaderBg.png) no-repeat center center;
    font-size: 24px;
    color: #fff;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 760px;
  }

  &__left {
    flex: 0 0 306px;
    width: 306px;
  }

  &__right {
    flex: 0 0 413px;
    width: 413px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bagetPopup__label {
      margin-bottom: 0;
    }

    .bagetPopup__label {

      span {
        &:first-child {
          @extend .black;
          text-transform: uppercase;
        }
      }
    }
  }

  &__label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      color: #fff;
    }

    input {
      margin: 0 5px 0 10px;
      border: none;
      border-radius: 100px;
      width: 70px;
      padding: 10px 15px;
      font-family: $font-family;
      font-size: 16px;
    }
  }

  .catalogBagets__itemName {
    color: #fff;
  }

  .catalogBagets__itemFeature {
    color: #fff;
  }

  hr {
    margin: 15px 0;
    border: none;
    height: 1px;
    background-color: #8f8f8f;
  }

  &__submit {
    width: auto;
    padding: 13px 25px 12px;
  }

  .fancybox-close-small {
    top: -40px;
    right: 0;
  }
}

.personal {

  &__category {
    margin-bottom: 10px;
  }

  &__header {
    margin-left: 60px;
    margin-bottom: 20px;

    span {
      display: inline-block;
      border-bottom: 3px solid;
      @extend .shadow;
      font-size: 24px;
      line-height: 1.4;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 40px;
  }

  &__item {
    margin-bottom: 20px;
    flex: 0 0 250px;
    width: 250px;
    text-align: center;
  }
  
  &__image {
    position: relative;

    img {
      max-width: 100%;
    }
  }

  &__info {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 40px;
    left: -63px;
    z-index: 55;
    border-radius: 15px;
    border: 6px solid rgba(255,255,255,0.5);
    width: 322px;
    padding: 20px 25px;
    background-color: #1d2b37;
    background-clip: padding-box;
    font-size: 14px;
    color: #fff;
    line-height: 1.3;
    text-align: left;
    @extend .transition;

    &.active {
      visibility: visible;
      opacity: 1;
    }

    &:before {
      content: '';
      position: absolute;
      right: 70px;
      top: -13px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-bottom: 13px solid #1d2b37;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }

  &__infoInner {

  }

  &__infoTrigger {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    border-radius: 100%;
    border: 4px solid #eeeeee;
    width: 30px;
    height: 30px;
    background-color: #c3c3c3;
    @extend .black;
    font-size: 18px;
    color: #eeeeee;
    box-sizing: border-box;
    text-align: center;
    cursor: help;
  }

  &__name {
    font-size: 20px;
    line-height: 1.3;
  }

  &__function {
    line-height: 1.3;
  }
}

.serts {

  &__inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 100px;
  }

  &__item {

  }
}

.video {
  z-index: 20 !important;

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 45px;
  }

  &__item {
    margin-bottom: 50px;
    flex: 0 0 560px;
    width: 560px;
    padding: 0 0 15px 0;
  }

  &__itemImage {
    display: block;
    position: relative;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 136px;
      height: 68px;
      background: url(../img/videoButtonSprite.png) no-repeat top center;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 136px;
        height: 68px;
        background: url(../img/videoButtonSprite.png) no-repeat bottom center;
        opacity: 0;
        @extend .transition;
      }
    }

    /*&:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      height: 60px;
      background: url(../img/splash2.png) no-repeat center center;
      background-size: cover;
    }*/

    &:hover {
      span {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &__itemInfo {
    margin: 10px auto 0;
    width: 430px;
  }

  &__itemName {
    @extend .link;
    @extend .shadow;
    font-size: 18px;
    color: $colorText;
    line-height: 1.1;
  }

  &__itemDescr {
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.2;
  }

  &__itemText {
    @extend .light;
    font-size: 16px;
    line-height: 1.3;
  }
  
  hr {
    margin: 12px 0 15px -5%;
    border: none;
    width: 110%;
    height: 1px;
    background-color: $colorText;
  }
}

.videoPage {
  text-align: center;
  z-index: 20 !important;

  .video__itemImage {
    display: inline-block;
    width: 885px;

    &:before {
      display: none;
    }
  }

  &__info {
    margin-top: -90px;
    text-align: left;
  }

  &__infoInner {
    margin: 0 auto;
    width: 870px;
  }

  &__name {
    @extend .shadow;
    font-size: 36px;
  }

  &__descr {
    line-height: 1.2;
  }

  hr {
    margin: 12px 0 15px -1%;
    border: none;
    width: 102%;
    height: 1px;
    background-color: $colorText;
  }

  .content {
    @extend .light;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;

    .container {
      width: 870px;
    }
  }
}

.cities {
  position: relative;
  background-color: $colorBaget;
  margin-bottom: -60px;
  padding-bottom: 80px;

  &__inner {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 440px;
    padding: 40px 30px 0 30px;
  }

  &__col {
    flex: 0 0 225px;
    width: 225px;
  }

  &__item {
    position: relative;
    margin-bottom: 15px;
    padding: 0 0 0 55px;

    &:last-child {
      margin-bottom: 0;

      .cities__city {
        border: none;
      }
    }
  }

  &__letter {
    position: absolute;
    left: 0;
    top: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #c3d5df;
    @extend .black;
    font-size: 20px;
    color: #fff;
  }

  &__name {
    display: inline-block;
    @extend .link-reverse;
    margin-bottom: 5px;
    @extend .black;
    color: $colorText;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__city {
    padding: 0 0 15px 0;

    &:last-child {
      border-bottom: 1px solid $colorText;
    }
  }

  &__columns {
    columns: 5;
  }
}

.contactPage {

  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  &__left {
    flex: 0 0 365px;
    width: 365px;
  }

  &__middle {
    flex: 0 0 425px;
    width: 425px;
    padding-bottom: 40px;
  }

  &__right {
    flex: 0 0 370px;
    width: 370px;
  }

  &__header {
    text-align: center;

    span {
      display: inline-block;
      border-bottom: 3px solid;
      padding-bottom: 5px;
      @extend .shadow;
      font-size: 24px;
    }
  }

  .owl-controls {
    text-align: center;
  }

  .owl-prev, .owl-next {
    position: relative;
    top: auto;
    display: inline-block;
    margin: 10px 5px 0 5px;
    width: 44px;
    height: 44px;
    background-color: #dfdfdf;

    &:hover {
      opacity: 1;
    }
  }


  .owl-prev {
    left: 0;

    &:before {
      left: 17px;
      border-right: 7px solid #fff;
    }
  }

  .owl-next {
    right: 0;

    &:before {
      right: 17px;
      border-left: 7px solid #fff;
    }
  }

  &__slider {
    width: 425px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 15;
      top: -1px;
      left: 0;
      width: 100%;
      height: 55px;
      background-image: url(../img/splash2.png);
      transform: rotate(180deg);
    }

    img {
      max-width: 100%;
    }
  }

  &__personalSlider {
    width: 370px;
    text-align: center;

    .personal__item {
      display: inline-block;
    }

    .owl-prev, .owl-next {
      position: absolute;
      top: 90px;
    }
  }
}

#map {
  position: relative;
  height: 500px;
  margin: -40px 0 -20px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    height: 80px;
    background-image: url(../img/splash.png);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 20;
    width: 100%;
    height: 80px;
    background-image: url(../img/splash.png);
    transform: rotate(180deg);
  }
}

.present {
  position: relative;
  margin-top: -8px;
  padding-top: 73px;
  padding-bottom: 90px;
  background-size: cover;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    height: 80px;
    background-image: url(../img/splash.png);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
  }

  &__left {
    flex: 0 0 360px;
    width: 360px;
  }

  &__middle {
    flex: 0 0 350px;
    width: 350px;

    &.therecolumn {
      flex: 0 0 750px;
      width: 750px;

      .present__listItem {
        flex: 0 0 30%;
        width: 30%;
        padding-left: 12px;
      }
    }
  }

  &__right {
    flex: 0 0 450px;
    width: 450px;
  }

  &__slider {
    width: 450px;
  }

  &__header {
    margin-bottom: 10px;
    border-bottom: 6px solid;
    padding-left: 90px;
    background: url(../img/present.png) no-repeat left top;
    color: #fff;

    span {
      @extend .shadow;
      font-size: 44px;
    }
  }

  &__text {
    margin-bottom: 15px;
    @extend .light;
    font-size: 15px;
    color: #fff;
    line-height: 1.3;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 290px;
  }

  &__list {
    @extend .ul-reset;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__listItem {
    position: relative;
    margin-bottom: 10px;
    flex: 0 0 39%;
    width: 39%;
    padding-left: 3%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 0;
      width: 4px;
      height: 2px;
      background-color: #fff;
    }
  }
  
  &__listLink {
    @extend .link-reverse;
    @extend .shadow;
    font-size: 15px;
    color: #fff;
  }

  .owl-pagination {
    margin-top: -14px;
  }

  &__sliderItem {
    display: block;
    font-size: 0;
    box-sizing: border-box;

    img {
      border: 2px solid #fff;
      width: 450px;
      height: 330px;
      box-sizing: border-box;
    }
  }

  .owl-next {
    top: auto;
    bottom: -22px;
    right: 15px;
    opacity: 1;
  }
}

.schema {

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    counter-reset: list;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 35px 0 35px;
    flex: 0 0 420px;
    width: 420px;

    &:before {
      counter-increment: list;
      content: "0" counter(list);
      display: block;
      position: absolute;
      top: -15px;
      left: 25px;
      border-bottom: 4px solid;
      @extend .black;
      font-size: 18px;
      color: lighten(#adadad, 10%);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -15px;
      width: 76px;
      height: 22px;
      background-image: url(../img/pinkArrow.png);
      opacity: 0.7;
    }

    &:nth-child(3n) {
      &:after {
        display: none;
      }
    }
  }

  &__itemLeft {
    flex: 0 0 182px;
    width: 182px;
    height: 144px;
    background-size: cover;
    background-position: center center;
  }

  &__itemRight {
    margin-left: 10px;
    flex: 0 1 auto;
  }

  &__itemHeader {
    margin: 25px 0 10px;
    @extend .shadow;
    font-size: 24px;
    text-transform: uppercase;
  }

  &__itemText {
    line-height: 1.3;
  }
}

.success {

  &__wrapper {
    background-color: transparent !important;
    color: #fff;
    text-align: center;

    .fancybox-close-small {
      display: none !important;
    }
  }

  &__image {
    margin: 0 auto;
    width: 767px;
    height: 600px;
    background: url(../img/success.png) no-repeat center center;
  }
}

.productPopup {
  display: none;
  margin: 0 !important;
  height: 90%;
  background-color: transparent !important;
  text-align: center;

  &__image {
    max-height: 90%;
  }

  &__link {
    @extend .link;
    @extend .shadow;
    font-size: 18px;
    color: #fff;
  }

  &__linkWrapper {
    margin-top: 15px;
  }
}

.has-error {
  .help-block {
    display: block;
  }
}

.help-block {
  display: none;
  margin-bottom: 15px;
  color: #ff8080;
}

.raschetForm {
  margin-top: 115px;

  &__steps {
    display: flex;
    justify-content: space-around;
  }

  &__step {
    border-left: 1px solid #e5e5e5;
    flex: 0 0 32%;

    &:first-child {
      border-left: none;
    }
  }

  &__stepInner {
    position: relative;
    padding: 50px 20px 0 20px;
  }

  &__digit {
    position: absolute;
    top: -100px;
    width: 114px;
    height: 144px;
    padding: 55px 0 0 33px;
    background-image: url(/resources/img/adigit.png);
    @extend .thin;
    font-size: 36px;
    color: #fff;
    box-sizing: border-box;

    &:after {
      content: 'шаг';
      display: block;
      position: absolute;
      right: -50px;
      top: 63px;
      @extend .regular;
      font-size: 18px;
      color: $colorText;
      text-transform: uppercase;
    }
  }

  &__name {
    font-size: 20px;
  }

  &__radioList {
    padding: 20px 0 0 55px;
  }

  &__radio {
    display: block;
    margin-bottom: 10px;

    input {
      display: none;

      &:checked + span {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    span {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 41px;
      padding-left: 60px;
      cursor: pointer;
      
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 41px;
        height: 41px;
        background: url(../img/radioA.png) no-repeat;
        @extend .transition;
      }

      &:before {
        opacity: 1;
        background-position: center bottom;
      }

      &:after {
        opacity: 0;
        background-position: center top;
      }
    }
  }

  .form-group {
    width: 100%;
  }

  &__input {
    border-radius: 28px;
    border: 4px solid #dbdbdb;
    width: 100%;
    padding: 15px 25px;
    box-sizing: border-box;
    @extend .transition;

    &:focus {
      border-color: darken(#dbdbdb, 15%);
    }
  }

  &__textarea {
    margin-top: 20px;
    height: 175px;
    resize: none;
  }

  &__photoHeader {
    margin: 15px 0;
  }

  &__file {
    [type="file"] {
      display: none;
    }

    span {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 100px;
      height: 51px;
      padding-left: 30px;
      background-color: #f54b65;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      @extend .transition;

      &:hover {
        background-color: darken(#f54b65, 10%);

        &:after {
          transform: rotate(180deg);
        }
      }
      
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 60px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        border: 1px dashed #fff;
        width: 0;
        height: 70%;
      }
      
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 23px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 19px;
        height: 13px;
        background-image: url(../img/abottom.png);
        @extend .transition;
      }
    }
  }

  &__fieldset {
    margin-top: 15px;

    .form-group {
      margin-bottom: 6px;
    }
  }

  .help-block {
    display: none !important;
  }

  .has-error {
    input {
      border-color: #ff8080 !important;
    }
  }

  &__submit {
    margin-top: 43px;
    border-radius: 100px;
    width: 100%;
    padding: 15px 0;
    background-color: #0b99e2;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    @extend .transition;

    &:hover {
      background-color: darken(#0b99e2, 15%);
    }
  }
  
  .loading {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 5px auto 0;
    
    &:before {
      display: block;
      position: relative !important;
    }
  }
}

.text1 {

  &__inner {
    border-top: 1px solid #e5e5e5;
    padding: 15px 0;
    line-height: 1.4;
    text-align: center;
  }
}

.akcia {
  position: relative;
  z-index: 2;
  margin-top: 30px;
  height: 390px;
  padding-top: 70px;
  background: url(../img/bgp.png) no-repeat center center;
  box-sizing: border-box;

  &__header {
    margin: 0 auto;
    width: 439px;
    height: 100px;
    padding: 42px 0 0 170px;
    background-image: url(../img/klWhite.png);
    @extend .shadow;
    font-size: 28px;
    color: #ca155e;
    text-transform: uppercase;
    box-sizing: border-box;
  }

  &__text {
    margin-top: 30px;
    @extend .shadow;
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
  }
}

.akciaImage {
  position: relative;
  top: -80px;
  margin-bottom: 50px;
  padding: 90px 0 50px;
  background-color: #f3f3f3;
  text-align: center;

  &__image {

  }

  &__header {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.flip-clock-divider .flip-clock-label {
  top: 105px;
  font-size: 14px;
  text-transform: uppercase;
}

.flip-clock-divider.hours .flip-clock-label {
  right: -90px;
}

.flip-clock-divider.minutes .flip-clock-label {
  right: -92px;
}

.flip-clock-divider.seconds .flip-clock-label {
  right: -95px;
}

.flip-clock-divider {
  float: none;
}

.flip-clock-wrapper {
  margin: 0;
  position: absolute;
}

.flip-clock-wrapper ul {
  float: none;
  display: inline-block;
}

#callback {
  .loading {
    display: none;
    position: absolute;
    right: 50px;
    bottom: 19px;
  }
}

#photo {
  .loading {
    display: none;
    position: absolute;
    right: 50px;
    bottom: 19px;
  }
}

.fancybox-button {
  @include respond-to(1000) {
    width: 80px;
    height: 80px;
  }
}