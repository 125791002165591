$mobile: 530px;
$tablet: 950px;
$desktop: 1128px;


@mixin respond-to($media) {
  @media only screen and (max-width: $media + px) {
    @content;
  }
}

@mixin apply-to($ltgt, $width) {
  $extrema: null;
  @if $ltgt == lt {
    $extrema: max;
  }
  @if $ltgt == gt {
    $extrema: min
  }

  @media only screen and (#{$extrema}-width: $width) {
    @content;
  }
}

.hidden-mobile {
  @include respond-to(425) {
    display: none !important;
  }
}

.hidden-tablet {
  @include respond-to(1024) {
    display: none !important;
  }
}

.hidden-desktop {
  @include respond-to(1280) {
    display: none !important;
  }
}

.visible-mobile {
  display: none;

  @include respond-to(425) {
    display: block !important;
  }
}