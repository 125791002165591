$site-width: 1280px;

$font-family: Museo, Arial, sans-serif;
$font-size: 14px;

$grid-gap: 10px;

$margin: 30px;

$colorText: #000;
$colorText2: #1c2b36;
$colorBlue: #0b99e2;
$colorBlue2: #0090a9;
$colorBlueLight: #2ba6e6;
$colorRed: #b60807;
$colorRedLight: #f54b65;
$colorGrey: #afafaf;
$colorFooter: #616161;
$colorSearch: #959595;
$colorPurple: #685d85;
$colorPink: #ca155e;
$colorBaget: #efefef;