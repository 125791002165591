@import "media";
@import "mixins";
@import "../settings";

sub {
  vertical-align: sub;
  font-size: small;
}

sup {
  vertical-align: super;
  font-size: small;
}

p {
  margin: 1em 0;
  line-height: 1.3;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

// hide placeholders on focus
input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

.field-bc {
  display: none;
}

.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

body {
  font-family: $font-family;
  font-size: $font-size;
  color: $colorText;
  line-height: 1;
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
}

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.fade {
  display: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.smaller {
  font-size: 90%;
}

:focus {
  outline: none;
}

:focus::-webkit-input-placeholder {
  color: transparent
}

:focus::-moz-placeholder {
  color: transparent
}

:focus:-moz-placeholder {
  color: transparent
}

:focus:-ms-input-placeholder {
  color: transparent
}

/* Micro ClearFix Mixin */
.clearfix {
  zoom: 1;
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently
                         not supported by any browser */
}

.grid {
  margin: 0 -$grid-gap/2;

  > .item {
    display: inline-block;
    vertical-align: top;
    margin: 0 $grid-gap/2 $grid-gap;
    box-sizing: border-box;
  }
}

.flex-grid {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -$grid-gap/2;

  &__item {
    margin: 0 $grid-gap/2 $grid-gap;
    box-sizing: border-box;
  }
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;

  img {
    align-self: flex-start;
  }
}

.container {
  @extend .block-center;
  width: $site-width;
  position: relative;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mrg {
  @extend .mrg-top;
  @extend .mrg-bottom;
}

.mrg-top {
  margin-top: $margin;
}

.mrg-bottom {
  margin-bottom: $margin;
}

input[name='BC'] {
  display: none;
}

form .form-group {
  display: inline-block;
  vertical-align: top;
}

input, textarea {
  font-family: $font-family;
  font-size: 16px;
}

.link {
  text-decoration: none;
  border-bottom: 1px solid;
  @extend .transition;

  &:hover {
    border-color: transparent;
  }
}

.link-reverse {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  @extend .transition;

  &:hover {
    border-color: inherit;
  }
}

.link-span {
  text-decoration: none;

  span {
    border-bottom: 1px solid;
    @extend .transition;

    &:hover {
      border-color: transparent;
    }
  }
}

.not-link {
  color: inherit;
  text-decoration: none;
}

.button1 {
  display: inline-block;
  border-radius: 100px;
  width: 100%;
  padding: 17px 0 15px;
  background-color: $colorBlue;
  @extend .black;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  @extend .transition;

  &_padding {
    width: auto !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  &_footer {
    padding-top: 12px;
    padding-bottom: 10px;
  }
  
  &:hover {
    background-color: darken($colorBlue, 10%);
  }
}

.button2 {
  display: inline-block;
  position: relative;
  border-radius: 100px;
  border: 3px solid $colorText;
  padding: 15px 18px 12px 48px;
  background-color: #fff;
  @extend .black;
  color: $colorText;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  @extend .transition;

  &_footer {
    padding-top: 11px;
    padding-bottom: 8px;
  }

  &_big {
    font-size: 18px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 16px;
    height: 19px;
    background-image: url(../img/downloadIcon.png);
    @extend .transition;
  }

  &:hover, &_active {
    border-color: $colorBlue;
    background-color: $colorBlue;
    color: #fff;

    &:before {
      filter: invert(100%);
    }
  }

  &_female {
    &:before {
      width: 17px;
      height: 21px;
      background-image: url(../img/feminin.png);
    }
  }

  &_male {
    &:before {
      width: 20px;
      height: 21px;
      background-image: url(../img/homme.png);
    }
  }

  &_holiday {
    &:before {
      left: 18px;
      width: 21px;
      height: 20px;
      background-image: url(../img/holiday.png);
    }
  }

  &_without {
    padding: 15px 18px 12px 18px;

    &:before {
      display: none;
    }
  }
}

.button3 {
  box-shadow: 0 2px 5px 0 $colorBlue2;
  border-radius: 100px;
  border-bottom: 4px solid #fff;
  flex: 0 0 305px;
  width: 305px;
  padding: 20px 0 15px 0;
  background-color: $colorBlue2;
  @extend .shadow;
  font-size: 20px;
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  @extend .transition;

  &:hover {
    background-color: #007091;
  }
}

.button4 {
  display: inline-block;
  position: relative;
  border-radius: 100px;
  border: 3px solid #fff;
  flex: 0 0 305px;
  width: 305px;
  padding: 15px 0 12px 35px;
  background-color: transparent;
  @extend .black;
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
  @extend .transition;

  &:before {
    content: '';
    position: absolute;
    left: 50px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 16px;
    height: 19px;
    background-image: url(../img/downloadIcon.png);
    filter: invert(100%);
    @extend .transition;
  }

  &:hover {
    background-color: #fff;
    color: #000;

    &:before {
      filter: invert(0%);
    }
  }

  &.padding-left {
    &:before {
      left: 15px;
    }
  }
}

.fancybox-infobar__body {
  display: none !important;
}

.fancybox-controls {
  @extend .container;
}

.fancybox-button--left {
  @extend .owl-prev;
  z-index: 99995;
  left: 0 !important;
}

.fancybox-button--right {
  @extend .owl-next;
  z-index: 99995;
  right: 0 !important;
}

.fancybox-button--left, .fancybox-button--right {
  &:hover {
    background-color: #fff;
  }
}

.fancybox-button--left:after, .fancybox-button--right:after {
  display: none !important;
}

.fancybox-slide>* {
  padding: 0 !important;
  overflow: visible !important;
}

.input {
  border: none;
  box-shadow: inset 0 2px 10px 0 grey;
  border-radius: 100px;
  flex: 0 0 305px;
  width: 305px;
  padding: 20px 20px 18px 60px;
  font-family: $font-family;
  font-size: $font-size;
  box-sizing: border-box;

  &[name="name"] {
    background: #fff url(../img/nameIcon.png) no-repeat 20px center;
  }

  &[name="phone"] {
    background: #fff url(../img/phoneIcon2.png) no-repeat 20px center;
  }

  &[name="email"] {
    background: #fff url(../img/emailIcon.png) no-repeat 20px center;
  }
}

textarea.input {
  resize: none;
  border-radius: 50px;
  height: 110px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: $colorText;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: $colorText;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $colorText;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $colorText;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: $colorText;
}
.headerLine {
  background: url(../img/line_01.png) no-repeat center center;
}
.header {
  margin: 20px 0 15px;
  @extend .headerLine;
  @extend .shadow;
  font-size: 34px;
  text-align: center;
  
  span {
    display: inline-block;
    padding: 0 20px;
    background-color: #fff;
  }
}
.fancybox-close-small {
  top: -10px;
  right: -60px;
  width: 42px;
  height: 42px;
  background-image: url(../img/close.png);

  &:after {
    display: none;
  }
}

.radio {
  display: block;
  cursor: pointer;

  &__inner  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__marker {
    position: relative;
    margin-right: 7px;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url(../img/spriteRadioButton.png) no-repeat center top;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url(../img/spriteRadioButton.png) no-repeat center bottom;
      opacity: 0;
      @extend .transition;
    }
  }

  input {
    display: none;

    &:checked + .radio__inner .radio__marker {
      &:after {
        opacity: 1;
      }
    }
  }
}

.radioWithImage {
  display: inline-block;
  cursor: pointer;

  &__image {
    display: block;
    margin-bottom: 10px;
    font-size: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__marker {
    position: relative;
    margin-right: 7px;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url(../img/spriteRadioButton.png) no-repeat center top;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url(../img/spriteRadioButton.png) no-repeat center bottom;
      opacity: 0;
      @extend .transition;
    }
  }

  input {
    display: none;

    &:checked + .radioWithImage__inner .radioWithImage__marker {
      &:after {
        opacity: 1;
      }
    }
  }
}